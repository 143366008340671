import React, { useState } from 'react';
import { Fade } from "react-reveal";
import { Image, List, Icon, Card, Reveal, Dimmer } from "semantic-ui-react";
import IntersectionVisible from "react-intersection-visible";

const textStyle = {
    color: '#3a4660'
}

const CompanyExperience = () => {
    return (<Card.Group style={{ width: '100%' }} itemsPerRow={1}>
        <Card>
            <Card.Content>
                <h2 style={{ marginBottom: "2px" }}><Icon><Image src='databook.jpg' /></Icon><a target="_blank" rel="noopener noreferrer" href="https://trydatabook.com">Databook</a></h2>
                <div>Software Engineering Intern - June 2019 to September 2019</div>
                <List>
                    <List.Item>
                        <Icon name='right triangle' />
                        <List.Content>
                            <List.Header>Databook Internal Dashboard: </List.Header>
                            <List.Description>Implemented application used by Databook engineers to launch and analyze status of workers. Designed and implemented graphical interface using React.js for business administrators to bulk-update data stored in production databases (Redis, MongoDB, PostgreSQL).</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <Icon name='right triangle' />
                        <List.Content>
                            <List.Header>Databook Onboarding Flow: </List.Header>
                            <List.Description>Implemented the onboarding flow for new users, enabling them to configure profile, linked Salesforce/Google accounts, notification settings and followed companies. (Used by 60% of client companies)</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <Icon name='right triangle' />
                        <List.Content>
                            <List.Header>Internal Logs API: </List.Header>
                            <List.Description>Created Node.js API that stores status/error logs of all tasks executed on the internal dashboard in MongoDB. Designed admin approval system for incoming changes used directly by CTO. (Logged 1,000+ changes)</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <Icon name='right triangle' />
                        <List.Content>
                            <List.Header>Pipedrive to Salesforce Migration: </List.Header>
                            <List.Description>Automated data filtering using Python and redesigned data schema for compatibility with Salesforce service schemas. Migrated data from 85% of Databook business staff to Salesforce CRM, used by over 50% of the total employees daily.</List.Description>
                        </List.Content>
                    </List.Item>
                </List>
            </Card.Content>
        </Card>
        <Card>
            <Card.Content>
                <h2 style={{ marginBottom: "2px" }}><Icon><Image src='teachly.png' /></Icon><a target="_blank" rel="noopener noreferrer" href="https://www.teachly.org">Teachly</a></h2>
                <div>Software Engineering Intern - June 2017 to September 2017</div>
                <List>
                    <List.Item>
                        <Icon name='right triangle' />
                        <List.Content>
                            <List.Header>Teachly Web Application: </List.Header>
                            <List.Description>Maintained and enhanced coursework, gradebook, and attendance pages, adding visualized data analytics for educator use. Implemented a customizable Collapsible Table component using Ember.js and HTML/Handlebars to support calendar view on Attendance page.</List.Description>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <Icon name='right triangle' />
                        <List.Content>
                            <List.Header>Attendance Data: </List.Header>
                            <List.Description>Created attendance data model, implemented API endpoints to query attendance information (Node.js), and developed frontend interface of the attendance page using Ember.js.</List.Description>
                        </List.Content>
                    </List.Item>
                </List>
            </Card.Content>
        </Card>
    </Card.Group>);
}

const Organizations = () => {
    return (
        <Card.Group itemsPerRow={5}>
            <Card href="https://upe.seas.ucla.edu/" target="_blank" rel="noopener noreferrer">
                <Card.Content>
                    <Reveal animated="fade">
                        <Reveal.Content visible>
                            <Image size='medium' src="upe.png" />
                        </Reveal.Content>
                        <Reveal.Content hidden>
                            <Image size='medium' src="upe.png" />
                            <Dimmer blurring="true" active>
                                <h4 inverted="true">UPE</h4>
                            </Dimmer>
                        </Reveal.Content>
                    </Reveal>
                </Card.Content>
            </Card>
            <Card href="http://www.uclaacm.com/" target="_blank" rel="noopener noreferrer">
                <Card.Content>
                    <Reveal animated="fade">
                        <Reveal.Content visible>
                            <Image size='medium' src="acm.png" />
                        </Reveal.Content>
                        <Reveal.Content hidden>
                            <Image size='medium' src="acm.png" />
                            <Dimmer blurring="true" active>
                                <h4 inverted="true">ACM</h4>
                            </Dimmer>
                        </Reveal.Content>
                    </Reveal>
                </Card.Content>
            </Card>
        </Card.Group>);
}

const About = (props) => {

    const [showTitle, setShowTitle] = useState(false);
    const [showText, setShowText] = useState(false);
    const [showOrgs, setShowOrgs] = useState(false);

    return (
        <div ref={props.experienceRef}>
            <IntersectionVisible
                onShow={e => {
                    setTimeout(() => {
                        setShowTitle(true);
                        setTimeout(() => {
                            setShowText(true);
                            setTimeout(() => {
                                setShowOrgs(true);
                            }, 600)
                        }, 600)
                    })
                }}>
                <Fade right when={showTitle}><h1 style={textStyle}><Icon name="building" style={{marginRight: '10px'}} />Experience</h1></Fade>
            </IntersectionVisible>
            <br />
            <Fade right when={showText}>
                <CompanyExperience />
            </Fade>
            <Fade right when={showOrgs}>
                <h1 style={textStyle}><Icon name="sitemap" style={{marginRight: '10px'}} />Organizations</h1>
                <Organizations />
            </Fade>
        </div>
    )
}

export default About;