import React, { useRef, useState, useEffect } from "react";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import About from "./pages/About";
import NewLanding from "./pages/NewLanding";
import Experience from "./pages/Experience";
import Sidebar from "./components/Sidebar";
import Terminal from "./components/Terminal";
import {
  Grid,
  Segment,
  Divider,
  Sticky,
  Ref,
  Icon,
  Image,
} from "semantic-ui-react";
import { Slide } from "react-reveal";
import Menu from "./components/Menu";
import { background } from "./styles/colors";
import _ from "lodash";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import LandingV3 from "./pages/LandingV3";
import "./App.css";

const App = () => {
  const introRef = useRef(null);
  const aboutRef = useRef(null);
  const projectsRef = useRef(null);
  const experienceRef = useRef(null);

  const contextRef = useRef(null);

  const [showMenu, setShowMenu] = useState(false);
  const [menuYOffset, setMenuYOffset] = useState(window.pageYOffset);
  const [lastScroll, setLastScroll] = useState(new Date());
  const [zIndex, setZIndex] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [useLegacy, setUseLegacy] = useState(window.innerWidth < 750);

  const scrollToRef = (ref) => {
    window.scrollTo({
      left: 0,
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  const getDims = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    if (!useLegacy && window.innerWidth < 750) {
      setUseLegacy(true);
      window.removeEventListener("resize", getDims);
    }
  }

  const adjustMenu = () => {
    if (new Date() - lastScroll > 300) {
      setMenuYOffset(window.pageYOffset);
    }
    setLastScroll(new Date());
  };

  useEffect(() => {
    window.addEventListener("scroll", _.debounce(adjustMenu, 100));
    window.addEventListener("resize", getDims);
  }, []);

  return (
    <Router>
      <Route
        exact={true}
        path="/deprecated"
        render={() => (
          <div>
            <div className="App" style={{ backgroundColor: "#c5e3ed" }}>
              <Grid stretched stackable columns={2}>
                <Grid.Column width={4} only="computer" stretched>
                  <Ref innerRef={contextRef}>
                    <Sticky context={contextRef}>
                      <Sidebar />
                    </Sticky>
                  </Ref>
                </Grid.Column>

                <Grid.Column width={12} stretched>
                  <Icon
                    size="big"
                    link
                    onClick={() => {
                      setShowMenu(!showMenu);
                      setZIndex(zIndex === 0 ? 500 : 0);
                    }}
                    style={{
                      position: "fixed",
                      top: 30,
                      right: 20,
                      zIndex: 1000,
                    }}
                  >
                    <Image
                      src={showMenu ? "backbtn.png" : "hamburgerbtn.png"}
                    />
                  </Icon>
                  <div
                    style={{
                      position: "absolute",
                      right: 0,
                      top: menuYOffset,
                      width: "100%",
                      transition: "top 800ms ease-in",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        zIndex: zIndex,
                        width: "100%",
                      }}
                    >
                      <Slide right when={showMenu}>
                        <Menu
                          hideMenu={() => {
                            setShowMenu(false);
                            setZIndex(0);
                          }}
                          introRef={() => scrollToRef(introRef)}
                          aboutRef={() => scrollToRef(aboutRef)}
                          projectsRef={() => scrollToRef(projectsRef)}
                          experienceRef={() => scrollToRef(experienceRef)}
                        />
                      </Slide>
                    </div>
                  </div>
                  <Segment style={{ backgroundColor: background, zIndex: 5 }}>
                    <Home
                      introRef={introRef}
                      aboutRef={() => scrollToRef(aboutRef)}
                    />
                    <Divider section />
                    <About aboutRef={aboutRef} />
                    <Divider section />
                    <Projects projectsRef={projectsRef} />
                    <Divider section />
                    <Experience experienceRef={experienceRef} />
                  </Segment>
                </Grid.Column>
              </Grid>
            </div>
            <div style={{ width: "100vw" }}></div>
          </div>
        )}
      />
      <Route
        path="/"
        exact={true}
        render={() => {
          if (useLegacy) {
            return (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  minHeight: "100vh",
                  backgroundColor: "#0C2E4E",
                  overflowY: "hidden",
                }}
              >
                <NewLanding style={{ height: "100vh", fontFamily: "Inter" }} />
              </div>
            );
          } else {
            return (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  minHeight: "100vh",
                  backgroundColor: "#252a33",
                  overflowY: "hidden",
                }}
              >
                {/* <LandingV3 style={{ height: "100vh", fontFamily: "Inter" }} activateLegacy={() => setUseLegacy(true)} /> */}
                <Terminal activateLegacy={() => setUseLegacy(true)} />
              </div>
            );
          }
        }}
      />
    </Router>
  );
};

export default App;
