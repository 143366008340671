import React, { useState, useEffect, useRef } from "react";
import { Fade } from "react-reveal";
import IntersectionVisible from "react-intersection-visible";
import { Segment, List, Image, Header, Icon, Button } from "semantic-ui-react";
import ReactRotatingText from "react-rotating-text";
import Emailform from "../components/Emailform";
import ParticlesContainer from "../components/ParticlesContainer";
import Terminal, { ColorMode, LineType } from 'react-terminal-ui';
import "../App.css";

const colors = {
  code: "#8194FF", // purple
  bottomBar: "#0A2540", // dark bluegreen
  secondary: "#b3b5c0", // gray
  primary: "#e5592b", // orange
};

const NewLanding = (props) => {
  const [codeLines, setCodeLines] = useState(0);
  const [lineNums, setLineNums] = useState("");
  const codeRef = useRef(null);

  useEffect(() => {
    if (codeRef.current != null) {
      const boundingRect = codeRef.current.getBoundingClientRect();
      const numLines = parseInt(
        boundingRect.height /
          (parseFloat(codeRef.current.style.lineHeight) * 16)
      );
      if (numLines !== codeLines) {
        setLineNums(
          [...Array(numLines).keys()]
            .map((line) => {
              return JSON.stringify(line + 1);
            })
            .join("\n")
        );
        setCodeLines(numLines);
      }
    }
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{ height: "100%", position: "absolute", overflowY: "scroll" }}
      >
        <div
          style={{
            borderLeftColor: colors.secondary,
            borderLeftStyle: "solid",
            borderLeftWidth: "3px",
            color: colors.secondary,
            margin: "20px 0px 0px 20px",
            padding: "0px 0px 0px 20px",
            maxWidth: "70%",
          }}
        >
          <div style={{ fontSize: "2.5em", color: colors.primary, lineHeight: '1.5em' }}>
            Michael Wu
          </div>
          <div
            style={{
              fontSize: "1.5em",
              color: colors.secondary,
              lineHeight: "1em",
            }}
          >
            a{" "}
            <span style={{ fontWeight: "bold", color: "#ffffff" }}>
              software engineer
            </span>{" "}
            based in the Bay Area
          </div>
          <div
            style={{
              marginTop: "16px",
              fontSize: "1.5em",
              color: colors.secondary,
              lineHeight: "1em",
            }}
          >
            I like
            <ReactRotatingText
              items={[
                " building scalable software infrastructure",
                " collaborating on projects with friends",
                " applying what's learned to life",
              ]}
              typingInterval={70}
              pause={1000}
            />
            <br />
          </div>
          <div style={{ marginTop: "16px" }}>
            <Button
              color="orange"
              circular
              icon="github"
              size="large"
              style={{ margin: "5px 5px 0px 0px" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/michaelw54"
            />
            <Button
              color="linkedin"
              circular
              icon="linkedin"
              size="large"
              style={{ margin: "5px 5px 0px 0px" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/michaelw54"
            />
            <Emailform style={{ margin: "5px 5px 0px 0px" }} />
          </div>
        </div>

        {/* vim editor starts here */}
        <div
          style={{
            width: "75%",
            backgroundColor: colors.bottomBar,
            margin: "20px 0px 0px 20px",
            padding: "0px 20px 0px 0px",
            fontWeight: "bold",
            color: "#ffffff",
          }}
        >
          <span className="dot" style={{ backgroundColor: "#FF5750" }}></span>{" "}
          <span className="dot" style={{ backgroundColor: "#FFBD2D" }}></span>{" "}
          <span className="dot" style={{ backgroundColor: "#29CB41" }}></span> 
          {/* <span style={{ marginLeft: '35%' }}>vim</span> */}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              borderLeftColor: colors.primary,
              borderLeftStyle: "solid",
              borderLeftWidth: "3px",
              maxWidth: "1%",
              fontSize: "1.2em",
              color: colors.secondary,
              lineHeight: "1.3em",
              margin: "0px 0px 0px 20px",
              padding: "0px 0px 0px 20px",
            }}
          >
            {lineNums}
          </div>
          <div
            ref={codeRef}
            style={{
              fontSize: "1.2em",
              color: colors.secondary,
              width: "70%",
              lineHeight: "1.3em",
              margin: "0px 0px 0px 20px",
              padding: "0px 0px 0px 20px",
            }}
          >
            <span style={{ fontWeight: "bold" }}>{"#include <About.me>"}</span>
            <br /> <br />
            I'm an undergraduate student pursuing a B.S. in Computer Science at
            the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://samueli.ucla.edu/"
              style={{ color: colors.primary }}
            >
              birthplace of the internet
            </a>
            . I'm passionate about developing applications that can make an
            impact in my community or influence the industry in a niche way.
            <br /> <br />
            At UCLA, you can catch me doing some work in Engineering VI or
            hitting the weights at BFIT. I'm an active member of{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.novaforgood.org/"
              style={{ color: colors.primary }}
            >
              Nova
            </a>
            , UCLA's premier technical consulting organization that aims to
            create technical solutions for nonprofits. While I'm not working on
            coursework or club projects, I'll sometimes shoot some hoops with
            friends or play some smash.
            <br /> <br />
            I love learning about new ideas, technology or experiences, so feel
            free to reach out to me.
            <br /> <br />
            Let's{" "}
            <a
              style={{ color: colors.primary }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/michaelw54"
            >
              connect
            </a>
            !
          </div>
        </div>

        {/* extra vim lines */}
        <div
          style={{
            borderLeftColor: colors.primary,
            borderLeftStyle: "solid",
            borderLeftWidth: "3px",
            maxWidth: "1%",
            fontSize: "1.2em",
            color: colors.secondary,
            lineHeight: "1.3em",
            margin: "0px 0px 0px 20px",
            padding: "0px 0px 0px 20px",
          }}
        >
          ~ ~ ~
        </div>

        {/* insert editor debug bar here */}
        <div
          style={{
            width: "75%",
            backgroundColor: colors.bottomBar,
            margin: "0px 0px 0px 20px",
            padding: "0px 20px 0px 20px",
            fontWeight: "bold",
            color: "#ffffff",
          }}
        >
          DEBUG: {"   "} "Michael.cpp" {"   "} {codeLines}L, 100C
        </div>

      </div>
      <ParticlesContainer style={{ position: "absolute" }} />
    </div>
  );
};

export default NewLanding;
