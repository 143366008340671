import React, { useState, useEffect } from 'react';
import { Fade } from "react-reveal";
import IntersectionVisible from "react-intersection-visible";
import { Icon } from "semantic-ui-react";

const textStyle = {
    color: '#3a4660'
}

const About = (props) => {

    const [showTitle, setShowTitle] = useState(false);
    const [showText, setShowText] = useState(false);

    const [dynamicHeight, setDynamicHeight] = useState("100%");

    useEffect(() => {
        // document.querySelectorAll('.home-wrapper').forEach(el => el.style.minHeight = layeredDiv.current.clientHeight);
        setDynamicHeight(props.aboutRef.current.clientHeight * 1.25);
    }, [props.aboutRef]);

    return (
        <div style={{ height: dynamicHeight }}>
            <div ref={props.aboutRef} style={{ position: "relative" }}>
                <IntersectionVisible
                    onShow={e => {
                        setTimeout(() => {
                            setShowTitle(true);
                            setTimeout(() => {
                                setShowText(true);
                            }, 600)
                        })
                    }}>
                    <Fade right when={showTitle}><h1 style={textStyle}><Icon name="address card" style={{ marginRight: '10px' }} />About me</h1></Fade>
                </IntersectionVisible>
                <br />
                <Fade right when={showText}>
                    <div style={{
                        ...textStyle,
                        width: '80%',
                        overflow: 'wrap',
                        fontSize: '1.15em',
                        fontWeight: '450'
                    }}>
                        I am an undergraduate student at UCLA's Samueli School of Engineering pursuing a B.S. in Computer Science and Engineering.
                        I attended Henry M. Gunn High School from 2014 to 2018 in Palo Alto of the San Francisco Bay Area, where I was born and raised.
                    <br /> <br />
                        I'm passionate about developing applications that can make an impact in my community or influence the industry in a niche way.
                        As an avid hackathon attendee, I'm always thinking about novel ideas to implement together with my friends.
                        I aim to gradually improve the world through technological enhancements and smart product designs, growing my technical skills as much as possible while I'm at it.
                    <br /> <br />
                        At UCLA, you can catch me doing some work in my favorite building, Engineering VI or at Bruin Runners, where I get my bi-daily exercise.
                        While I'm not working on school work or other projects, I'll sometimes ball out with a couple of friends or play some video games.
                        I also love learning about new ideas, technology, and experiences, so feel free to reach out to me!
                    <br /> <br />
                        Let's connect.
                </div>
                </Fade>
            </div>
        </div>
    )
}

export default About;