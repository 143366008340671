import React, { useState } from "react";
import { Form, Modal, Button, Icon } from "semantic-ui-react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const awsLambdaUrl = "https://s2dfc0fbic.execute-api.us-east-2.amazonaws.com/default/forward_msg";

const Emailform = () => {

    const [formData, setFormData] = useState({
        name: '',
        subject: '',
        email: '',
        message: '',
        captcha: ''
    });
    const [modalOpen, setModalOpen] = useState(false);

    const handleSubmit = () => {
        const { name, subject, email, message, captcha } = formData;
        if (![name.replace(' ', ''), subject.replace(' ', ''), email.replace(' ', ''), message.replace(' ', '')].includes('')) {
            axios.post(awsLambdaUrl, {
                name,
                subject,
                email,
                message,
                "g-recaptcha-response": captcha
            }).then(res => {
                if (res.data === "success") {
                    setModalOpen(false);
                }
            }).catch(err => {
                window.alert("Please complete the Captcha.");
            });
        }
    }

    const handleChange = (e, { name, value }) => {
        let copied = Object.assign(formData);
        copied[name] = value;
        setFormData(copied);
    }

    const captchaChange = (value) => {
        let copied = Object.assign(formData);
        copied.captcha = value;
        setFormData(copied);
    }

    return (
        <Modal
            trigger={<Button color="google plus" style={{ margin: '5px 5px 0px 0px' }} circular icon="mail" size="large" onClick={() => setModalOpen(true)} />}
            closeIcon open={modalOpen} onClose={() => setModalOpen(false)}>
            <Modal.Header>Contact Me</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Input required
                        placeholder="Full Name"
                        name="name"
                        onChange={handleChange}
                    />
                    <Form.Input required
                        placeholder="Subject"
                        name="subject"
                        onChange={handleChange}
                    />
                    <Form.Input required
                        placeholder="Email"
                        name="email"
                        onChange={handleChange}
                    />
                    <Form.TextArea required
                        placeholder="Type your message here..."
                        name="message"
                        onChange={handleChange}
                    />
                    <ReCAPTCHA
                        sitekey="6LfZkrgUAAAAAINnDQNIcGdu9oKbniSxLvyIhYDV"
                        onChange={captchaChange}
                    /> <br />
                    <Button color='blue' type="submit" onClick={handleSubmit}>Submit</Button>
                </Form>
            </Modal.Content>
        </Modal>
    )
}

export default Emailform;