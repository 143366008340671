import React, { useState, useEffect } from 'react';
import { Fade } from "react-reveal";
import { Card, Image, Reveal, Dimmer, Icon, Responsive } from "semantic-ui-react";
import IntersectionVisible from "react-intersection-visible";
import Carousel from "semantic-ui-carousel-react";

const textStyle = {
    color: '#3a4660'
}

const SnapLangCard = () => (
    <Card target="_blank" href="https://devpost.com/software/snaplang">
        <Reveal animated='fade'>
            <Reveal.Content visible>
                <Image src='snaplangdemo.png' style={{ width: "500px", height: "200px" }} />
            </Reveal.Content>
            <Reveal.Content hidden>
                <Image src='snaplangdemo.png' style={{ width: "500px", height: "200px" }} />
                <Dimmer blurring="true" active>
                    <h4 inverted="true">Click to see more!</h4>
                </Dimmer>
            </Reveal.Content>
        </Reveal>
        <Card.Content>
            <Card.Header>SnapLang</Card.Header>
            <Card.Meta>PennApps XX Submission</Card.Meta>
            <Card.Description>Centralized platform for language-learning analytics and resources. Identify objects, learn them in a foreign language, and reinforce what you've learned with a personal dashboard.</Card.Description>
        </Card.Content>
    </Card>
);

const KidsEmoteCard = () => (
    <Card target="_blank" href="https://devpost.com/software/emotionar">
        <Reveal animated='fade'>
            <Reveal.Content visible>
                <Image src='kidsemotedemo.png' style={{ width: "500px", height: "200px" }} />
            </Reveal.Content>
            <Reveal.Content hidden>
                <Image src='kidsemotedemo.png' style={{ width: "500px", height: "200px" }} />
                <Dimmer blurring="true" active>
                    <h4 inverted="true">Click to see more!</h4>
                </Dimmer>
            </Reveal.Content>
        </Reveal>
        <Card.Content>
            <Card.Header>KidsEmote</Card.Header>
            <Card.Meta>TreeHacks 2019 Submission: Best Use of GCP Winner</Card.Meta>
            <Card.Description>A fun, interactive mobile app that leverages augmented reality and deep learning to help autistic children understand emotions from facial expressions.</Card.Description>
        </Card.Content>
    </Card>
);

const PackerCard = () => (
    <Card>
        <Reveal animated='fade'>
            <Reveal.Content visible>
                <Image src='packerdemo.jpg' style={{ width: "500px", height: "200px" }} />
            </Reveal.Content>
            <Reveal.Content hidden>
                <Image src='packerdemo.jpg' style={{ width: "500px", height: "200px" }} />
                <Dimmer blurring="true" active>
                    <h4 inverted="true">This project is still under development!</h4>
                </Dimmer>
            </Reveal.Content>
        </Reveal>
        <Card.Content>
            <Card.Header>Packer Travel Organizer</Card.Header>
            <Card.Meta>Project Director</Card.Meta>
            <Card.Description>Web application that allows a centralized interface for users to plan trips, fork shared trips from other users and share their own trips. Trip organizers have the ability to customize financials, itinerary, participants and user permissions.</Card.Description>
        </Card.Content>
    </Card>
);

const GeoCheckCard = () => (
    <Card target="_blank" href="https://devpost.com/software/geocheck">
        <Reveal animated='fade'>
            <Reveal.Content visible>
                <Image src='geocheckdemo.png' style={{ width: "500px", height: "200px" }} />
            </Reveal.Content>
            <Reveal.Content hidden>
                <Image src='geocheckdemo.png' style={{ width: "500px", height: "200px" }} />
                <Dimmer blurring="true" active>
                    <h4 inverted="true">Click to see more!</h4>
                </Dimmer>
            </Reveal.Content>
        </Reveal>
        <Card.Content>
            <Card.Header>GeoCheck</Card.Header>
            <Card.Meta>LA Hacks 2019 Submission</Card.Meta>
            <Card.Description>Dual platform (iOS and web) application that leverages low-cost SMS messaging, natural language processing, and GPS capabilities to provide a clear and dynamic picture of regions most in need of emergency support during an earthquake event.</Card.Description>
        </Card.Content>
    </Card>
);

const elements = [
    {
        render: () => {
            return <SnapLangCard />
        }
    },
    {
        render: () => {
            return <KidsEmoteCard />
        }
    }, {
        render: () => {
            return <PackerCard />
        }
    }, {
        render: () => {
            return <GeoCheckCard />
        }
    }
];

const Projects = (props) => {

    const [showTitle, setShowTitle] = useState(false);
    const [showText, setShowText] = useState(false);
    const [dynamicHeight, setDynamicHeight] = useState("100%");

    useEffect(() => {
        // document.querySelectorAll('.home-wrapper').forEach(el => el.style.minHeight = layeredDiv.current.clientHeight);
        setDynamicHeight(props.projectsRef.current.clientHeight * 1.25);
    }, [props.projectsRef]);

    return (
        <div style={{ height: dynamicHeight }}>
            <div ref={props.projectsRef}>
                <IntersectionVisible
                    onShow={e => {
                        setTimeout(() => {
                            setShowTitle(true);
                            setTimeout(() => {
                                setShowText(true);
                            }, 300)
                        }, 300)
                    }}
                >
                    <Fade right when={showTitle}><h1 style={textStyle}><Icon name="folder open" style={{ marginRight: '10px' }} />Featured Projects</h1></Fade>
                </IntersectionVisible>
                <br />
                <Fade right when={showText}>
                    <Responsive minWidth={992}>
                        <Card.Group itemsPerRow={4} style={{ marginRight: '10px'}}>
                            <SnapLangCard />
                            <KidsEmoteCard />
                            <PackerCard />
                            <GeoCheckCard />
                        </Card.Group>
                    </Responsive>
                    <Responsive as={Card} maxWidth={993}>
                        <Carousel
                            elements={elements}
                            duration={10000}
                            animation='slide left'
                            showNextPrev={true}
                            showIndicators={true}
                        />
                    </Responsive>
                </Fade>
            </div>
        </div>
    )
}

export default Projects;