import React, { useState, useRef, useEffect } from 'react';
import { Icon, Image, Responsive, Segment, List } from "semantic-ui-react";
import { Fade } from "react-reveal";
import ParticlesContainer from "../components/ParticlesContainer";
import IntersectionVisible from "react-intersection-visible";
import Emailform from "../components/Emailform";

const textStyle = {
    color: '#3a4660'
}

const centering = {
    display: 'flex',
    justifyContent: 'center'
}

const Home = (props) => {

    const [showTitle, setShowTitle] = useState(false);
    const [showRole, setShowRole] = useState(false);
    const [showInterests, setShowInterests] = useState(false);
    const [showScrollPrompt, setShowScrollPrompt] = useState(false);

    const [dynamicHeight, setDynamicHeight] = useState("100%");
    const layeredDiv = useRef(null);

    useEffect(() => {
        // document.querySelectorAll('.home-wrapper').forEach(el => el.style.minHeight = layeredDiv.current.clientHeight);
        setDynamicHeight(layeredDiv.current.clientHeight * 1.4);
    }, []);

    return (
        <div ref={props.introRef} style={{
            display: 'flex',
            flexDirection: 'column',
            position: "relative",
            height: window.innerHeight > dynamicHeight ? window.innerHeight : dynamicHeight
        }}>
            <ParticlesContainer />
            <div ref={layeredDiv} style={{
                position: "absolute",
                width: "100%"
            }}>
                <IntersectionVisible
                    onShow={e => {
                        setTimeout(() => {
                            setShowTitle(true)
                            setTimeout(() => {
                                setShowRole(true)
                                setTimeout(() => {
                                    setShowInterests(true);
                                    setShowScrollPrompt(true);
                                }, 600)
                            }, 600)
                        })
                    }}
                >
                    <Fade right when={showTitle}>
                        <Responsive maxWidth={992} style={{ ...centering, margin: '15px' }}>
                            <Image src='profile.jpg' circular bordered style={{height: '30vh'}} />
                        </Responsive>
                        <h1 style={{ ...centering, color: '#3a4660', fontSize: '45px', textAlign: 'center' }}><span>Hi, I am Michael.</span></h1>
                    </Fade>
                </IntersectionVisible>
                <Fade right when={showRole}>
                    <div style={{ ...centering, margin: '10px' }}>
                        <h3 style={{
                            ...centering, ...textStyle, overflow: 'wrap', display: 'table', width: 'auto', border: '2px solid #3a4660', borderRadius: '5px', padding: '10px', textAlign: 'center'
                        }}>
                            <span>Full-stack developer</span> | <span>Software Engineer</span> | <span>UCLA Bruin</span>
                    </h3>
                    </div>
                </Fade>
                <br />
                <Fade right when={showInterests}>
                    <div style={{
                        ...textStyle,
                        fontSize: '1.15em',
                        fontWeight: '450'
                    }}>
                        <h3 style={centering}>I love to...</h3>
                        <div style={centering}>Create fascinating projects with friends</div>
                        <div style={centering}>Sprout novel ideas with entrepreneurs</div>
                        <div style={centering}>Apply what's learned to life</div>
                    </div>

                    <div style={centering}>
                        <Responsive as={Segment} maxWidth={992} style={{ ...centering, marginTop: '15px', width: "80%" }}>
                            <List divided relaxed>
                                <List.Item>
                                    <List.Content><Emailform /></List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name="mail" />
                                    <List.Content>
                                        michaelw1@g.ucla.edu
                                </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name="github" />
                                    <List.Content><a target="_blank" rel="noopener noreferrer" href='https://www.github.com/michaelw54'>Github.com/michaelw54</a></List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name="linkedin" />
                                    <List.Content><a target="_blank" rel="noopener noreferrer" href='https://www.linkedin.com/in/michaelw54'>Linkedin.com/in/michaelw54</a></List.Content>
                                </List.Item>
                            </List>
                        </Responsive>
                    </div>
                </Fade>
                <Fade right when={showScrollPrompt}>
                    <div style={{ marginTop: '50px', visible: showScrollPrompt }}>
                        <div style={{ ...textStyle, ...centering, fontSize: '1.15em' }}>Scroll down to learn more... </div>
                        <div style={centering}>
                            <Icon size='huge' onClick={props.aboutRef}><Image src="scrolldown.gif" /></Icon>
                        </div>
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default Home;