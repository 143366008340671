import React, { useRef, useState, useEffect } from "react";
import ReactRotatingText from "react-rotating-text";
import {
  Card,
  Image,
  Reveal,
  Dimmer,
  Icon,
  Segment,
  Header,
  List,
  Button,
} from "semantic-ui-react";
import "../App.css";
import EmailForm from "./Emailform";

const terminalColors = {
  about: "#8194FF",
  experiences: "#00D1FC",
  projects: "#FFD96A",
  clear: "#FFA956",
};

const ProjectCard = (props) => {
  const { link, img, title, meta, desc } = props;
  return (
    <Card target="_blank" href={link} style={{ backgroundColor: "#343941" }}>
      <Reveal animated="fade">
        <Reveal.Content visible>
          <Image src={img} style={{ width: "500px", height: "200px" }} />
        </Reveal.Content>
        <Reveal.Content hidden>
          <Image src={img} style={{ width: "500px", height: "200px" }} />
          <Dimmer blurring="true" active>
            {link ? (
              <h4 inverted="true">Click to see more!</h4>
            ) : (
              <h4 inverted="true">Under Development!</h4>
            )}
          </Dimmer>
        </Reveal.Content>
      </Reveal>
      <Card.Content>
        <Card.Header style={{ color: terminalColors.projects }}>
          {title}
        </Card.Header>
        <Card.Meta style={{ color: terminalColors.projects }}>{meta}</Card.Meta>
        <Card.Description style={{ color: "white" }}>{desc}</Card.Description>
      </Card.Content>
    </Card>
  );
};

const SnapLangCard = () => (
  <ProjectCard
    title="SnapLang"
    meta="PennApps XX Submission"
    desc="Centralized platform for language-learning analytics and resources. Identify objects, learn them in a foreign language, and reinforce what you've learned with a personal dashboard."
    img="snaplangdemo.png"
    link="https://devpost.com/software/snaplang"
  />
);

const KidsEmoteCard = () => (
  <ProjectCard
    title="KidsEmote"
    meta="TreeHacks 2019 Submission: Best Use of GCP Winner"
    desc="A fun, interactive mobile app that leverages augmented reality and deep learning to help autistic children understand emotions from facial expressions."
    img="kidsemotedemo.png"
    link="https://devpost.com/software/emotionar"
  />
);

const PackerCard = () => (
  <ProjectCard
    title="Packer Travel Organizer"
    meta="Project Lead"
    desc="Web application that allows a centralized interface for users to plan trips, fork shared trips from other users and share their own trips. Trip organizers have the ability to customize financials, itinerary, participants and user permissions."
    img="packerdemo.jpg"
  />
);

const GeoCheckCard = () => (
  <ProjectCard
    title="GeoCheck"
    meta="LA Hacks 2019 Submission"
    desc="Dual platform (iOS and web) application that leverages low-cost SMS messaging, natural language processing, and GPS capabilities to provide a clear and dynamic picture of regions most in need of emergency support during an earthquake event."
    img="geocheckdemo.png"
    link="https://devpost.com/software/geocheck"
  />
);

const Terminal = (props) => {
  const [lines, setLines] = useState([]);
  const [currLine, setCurrLine] = useState("");
  const [history, setHistory] = useState([]);
  const [upIndex, setUpIndex] = useState(-1);

  const OptOut = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <p
          style={{
            flexDirection: "column",
            color: "#ffffff",
            margin: "15px 5px 0px 0px",
            fontSize: "0.75em",
          }}
        >
          Not familiar with terminal?{" "}
          <a
            style={{ flexDirection: "column", fontSize: "0.75em" }}
            href="#"
            onClick={props.activateLegacy}
          >
            Click Here
          </a>
        </p>
      </div>
    );
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      let colored = "> " + currLine;
      if (currLine === "experiences") {
        colored = (
          <span>
            {">"}{" "}
            <span style={{ color: terminalColors.experiences }}>
              {currLine}
            </span>
          </span>
        );
      } else if (currLine === "projects") {
        colored = (
          <span>
            {">"}{" "}
            <span style={{ color: terminalColors.projects }}>{currLine}</span>
          </span>
        );
      } else if (currLine === "about me") {
        colored = (
          <span>
            {">"}{" "}
            <span style={{ color: terminalColors.about }}>{currLine}</span>
          </span>
        );
      } else if (currLine === "clear") {
        colored = (
          <span>
            {">"}{" "}
            <span style={{ color: terminalColors.clear }}>{currLine}</span>
          </span>
        );
      }
      let newLines = [...lines, colored];

      if (currLine === "experiences") {
        newLines.push(
          <ul style={{ maxWidth: "800px" }}>
            <li>
              <a
                style={{ color: "#65DEF4", display: "flex" }}
                href="https://robinhood.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ color: "#33FF00", flexDirection: "row" }}>
                  Robinhood
                </span>
                <Image
                  src="rh.png"
                  style={{
                    width: "35px",
                    flexDirection: "row",
                    verticleAlign: "middle",
                  }}
                />
                <span style={{ color: "#33FF00", flexDirection: "row" }}>
                  -- Marketdata Backend
                </span>
              </a>
              <span style={{ fontWeight: "bold" }}>
                Backend Engineering Intern
              </span>
              : June 2021 - Sept 2021 <br />
              Designed data models and server API for ETP data ingestion from
              Morningstar. Extended streaming infrastructure to support database
              consistent batch redis writes and offset checkpointing from Kafka
              consumers.
            </li>
            <li>
              <a
                style={{ color: "#65DEF4", display: "flex" }}
                href="https://engineering.fb.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ color: "#65a6fc", flexDirection: "row" }}>
                  Facebook
                </span>
                <Image
                  src="fb.png"
                  style={{
                    margin: "0px 7px 0px 7px",
                    width: "35px",
                    flexDirection: "row",
                    verticleAlign: "middle",
                  }}
                />
                <span style={{ color: "#65a6fc", flexDirection: "row" }}>
                  -- Ownership Discovery Eng
                </span>
              </a>
              <span style={{ fontWeight: "bold" }}>
                Software Engineering Intern
              </span>
              : Jan 2021 - Apr 2021 <br />
              Built infrastructure for ownership triaging of internal privacy
              mitigations. Designed data models to support oncall ownership of
              PFH (Product Feature Hierarchy) Nodes.
            </li>
            <li>
              <a
                style={{ color: "#65DEF4", display: "flex" }}
                href="https://engineering.fb.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ color: "#65a6fc", flexDirection: "row" }}>
                  Facebook
                </span>
                <Image
                  src="fb.png"
                  style={{
                    margin: "0px 7px 0px 7px",
                    width: "35px",
                    flexDirection: "row",
                    verticleAlign: "middle",
                  }}
                />
                <span style={{ color: "#65a6fc", flexDirection: "row" }}>
                  -- Community Products Integrity Eng
                </span>
              </a>
              <span style={{ fontWeight: "bold" }}>
                Software Engineering Intern
              </span>
              : June 2020 - Sept 2020 <br />
              Contributed to infrastructure for integrity strikes system to take
              down violating content. Expanded functionality of content review
              platform.
            </li>
            <li>
              <a
                style={{ color: "#65DEF4", display: "flex" }}
                href="https://trydatabook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span style={{ color: "#f54242", flexDirection: "row" }}>
                  Databook
                </span>
                <Image
                  src="databook.png"
                  style={{
                    margin: "0px 7px 0px 7px",
                    width: "35px",
                    flexDirection: "row",
                    verticleAlign: "middle",
                  }}
                />
                <span style={{ color: "#f54242", flexDirection: "row" }}>
                  -- Business Automation
                </span>
              </a>
              <span style={{ fontWeight: "bold" }}>
                Software Engineering Intern
              </span>
              : June 2019 - Sept 2019 <br />
              Implemented dashboard used by engineering team to launch and
              analyze status of ETL jobs and enabled business admin to
              bulk-update data stored in production databases.
            </li>
          </ul>
        );
      } else if (currLine === "projects") {
        newLines.push(
          <div style={{ lineHeight: "1em" }}>
            <Card.Group itemsPerRow={4} style={{ marginRight: "10px" }}>
              <SnapLangCard />
              <KidsEmoteCard />
              <PackerCard />
              <GeoCheckCard />
            </Card.Group>
          </div>
        );
      } else if (currLine === "about me") {
        newLines.push(
          <div style={{ margin: "0px 20px 0px 20px", maxWidth: "800px" }}>
            I'm an undergraduate student pursuing a B.S. in Computer Science at
            the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://samueli.ucla.edu/internet50/"
              style={{ color: terminalColors.about }}
            >
              birthplace of the internet
            </a>
            . I'm passionate about developing applications that can make an
            impact in my community or influence the industry in a niche way.
            <br /> <br />
            At UCLA, you can catch me doing some work in Engineering VI, hitting
            the weights at BFIT or at my{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.northerncafela.com/"
              style={{ color: terminalColors.about }}
            >
              favorite restaurant
            </a>{" "}
            in Westwood. I'm an active member of{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.novaforgood.org/"
              style={{ color: terminalColors.about }}
            >
              Nova
            </a>
            , UCLA's premier technical consulting organization that aims to
            create technical solutions for nonprofits. While I'm not on the
            grind, I'll sometimes shoot some hoops, play the guitar or hang out
            with friends.
            <br /> <br />
            I love learning about new ideas, technology or experiences, so feel
            free to reach out to me.
            <br /> <br />
            Let's{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/michaelw54"
              style={{ color: terminalColors.about }}
            >
              connect
            </a>
            !
          </div>
        );
      } else if (currLine === "clear") {
        newLines = [];
      } else if (currLine.trim() === "") {
        newLines.push("")
      } else {
        newLines.push(
          <div>Command not recognized. Refer above for available commands.</div>
        );
      }

      setLines(newLines);
      setHistory([currLine, ...history]);
      setCurrLine("");
      setUpIndex(-1);
    } else if (e.keyCode === 9) {
      // Tab
      e.preventDefault();
      if (currLine.length >= 1 && currLine.slice(0,1) === "e") {
        setCurrLine("experiences");
      } else if (currLine.length >= 1 && currLine.slice(0,1) === "p") {
        setCurrLine("projects");
      } else if (currLine.length >= 1 && currLine.slice(0,1) === "a") {
        setCurrLine("about me");
      } else if (currLine.length >= 1 && currLine.slice(0,1) === "c") {
        setCurrLine("clear");
      }
    } else if (e.keyCode === 38) {
      // Up
      // history
      if (upIndex + 1 < history.length) {
        setCurrLine(history[upIndex + 1]);
        setUpIndex(upIndex + 1);
      }
    } else if (e.keyCode === 40) {
      // Down
      // history
      if (upIndex - 1 >= 0) {
        setCurrLine(history[upIndex - 1]);
        setUpIndex(upIndex - 1);
      }
    }
  };

  const handleInput = (e) => {
    setCurrLine(e.target.value);
  };

  return (
    <div
      onKeyDown={handleEnter}
      className="Terminal"
      style={{
        margin: "20px 0px 20px 20px",
        color: "white",
        lineHeight: "2em",
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            marginLeft: "15px",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <span style={{ fontWeight: "bold", fontSize: "3em" }}>
            Michael Wu
          </span>

          <ReactRotatingText
            items={["Software Engineer", "UCLA Bruin"]}
            typingInterval={70}
            pause={2000}
          />

          <div style={{ marginTop: "15px" }}>
            <Button
              color="grey"
              circular
              icon="github"
              size="large"
              style={{ margin: "5px 5px 0px 0px" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/michaelw54"
            />
            <Button
              color="linkedin"
              circular
              icon="linkedin"
              size="large"
              style={{ margin: "5px 5px 0px 0px" }}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/michaelw54"
            />
            <EmailForm style={{ margin: "5px 5px 0px 0px" }} />
          </div>
          <OptOut style={{ marginTop: "15px" }} />
        </div>
      </div>
      <div style={{ marginLeft: "15px" }}>
        <div style={{ margin: "20px 0px 0px 0px" }}>
          Available Commands:
          <ul>
            <li>
              <span style={{ color: terminalColors.about }}>about me</span> --
              learn more about Michael
            </li>
            <li>
              <span style={{ color: terminalColors.experiences }}>
                experiences
              </span>{" "}
              -- list directory contents of experiences folder
            </li>
            <li>
              <span style={{ color: terminalColors.projects }}>projects</span>{" "}
              -- list directory contents of projects folder
            </li>
            <li>
              <span style={{ color: terminalColors.clear }}>clear</span> --
              clear terminal
            </li>
          </ul>
        </div>
        {lines.map((l) => (
          <div>
            {l}
          </div>
        ))}
        ${" "}
        <input
          style={{
            background: "#252a33",
            border: 0,
            outline: "None",
            color: "white",
            width: "70vw",
            fontFamily: "Source Code Pro",
          }}
          type="text"
          value={currLine}
          placeholder="Type your command here"
          onChange={handleInput}
        />
      </div>
    </div>
  );
};

export default Terminal;
