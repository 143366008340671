import React from 'react';
import { Segment, Card } from "semantic-ui-react";
import { introduction, about, projects, experience, introductionPage } from "../styles/colors";

const centering = {
    display: 'flex',
    justifyContent: 'center'
}

const Menu = (props) => {
    return (
        <Segment style={{ height: '100vh', width: '100%', backgroundColor: introductionPage }}>
            <div style={{ ...centering, margin: '40px' }}>
                <Card style={{ backgroundColor: introduction }} href="#intro" onClick={() => {
                    props.hideMenu();
                    props.introRef();
                }} description={<strong style={{ ...centering, color: 'black' }}>INTRODUCTION</strong>} />
            </div>
            <div style={{ ...centering, margin: '40px' }}>
                <Card style={{ ...centering, backgroundColor: about }} href="#about" onClick={() => {
                    props.hideMenu();
                    props.aboutRef();
                }} description={<strong style={{ ...centering, color: 'black' }}>ABOUT</strong>} />
            </div>
            <div style={{ ...centering, margin: '40px' }}>
                <Card style={{ ...centering, backgroundColor: projects }} href="#projects" onClick={() => {
                    props.hideMenu();
                    props.projectsRef();
                }} description={<strong style={{ ...centering, color: 'black' }}>PROJECTS</strong>} />
            </div>
            <div style={{ ...centering, margin: '40px' }}>
                <Card style={{ ...centering, backgroundColor: experience }} href="#experience" onClick={() => {
                    props.hideMenu();
                    props.experienceRef()
                }} description={<strong style={{ ...centering, color: 'black' }}>EXPERIENCE</strong>} />
            </div>
        </Segment>
    );
}

export default Menu;