import React from 'react';
import { Segment, List, Image, Header, Icon } from 'semantic-ui-react';
import Emailform from "./Emailform";
import { background } from '../styles/colors';

const centering = {
    display: 'flex',
    justifyContent: 'center'
}

const Sidebar = () => {
    return (
        <Segment style={{ backgroundColor: background, height: window.innerHeight }}>
            <div>
                <Image src='profile.jpg' circular bordered />
                <Header as='h2' style={centering}>Michael Wu</Header>
                <Header as='h4' style={centering}>Your friendly neighborhood CS student</Header>
                <div>
                    <List divided relaxed selection>
                        <List.Item style={{...centering, margin: '10px'}}>
                            <List.Content><Emailform /></List.Content>
                        </List.Item>
                        <List.Item style={{...centering, margin: '10px'}}>
                            <List.Content><Icon name="mail" />michaelw1@g.ucla.edu</List.Content>
                        </List.Item>
                        <List.Item style={{...centering, margin: '10px'}}>
                            <List.Content><Icon name="github" /><a target="_blank" rel="noopener noreferrer" href='https://www.github.com/michaelw54'>Github.com/michaelw54</a></List.Content>
                        </List.Item>
                        <List.Item style={{...centering, margin: '10px'}}>
                            <List.Content><Icon name="linkedin" /><a target="_blank" rel="noopener noreferrer" href='https://www.linkedin.com/in/michaelw54'>Linkedin.com/in/michaelw54</a></List.Content>
                        </List.Item>
                    </List>
                </div>
            </div>
        </Segment>
    )
}

export default Sidebar;